import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = {
  "aria-describedby": "lazy-load-data-table-genesis",
  class: "w-full mt-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Skeleton = _resolveComponent("Skeleton")!

  return (_openBlock(), _createBlock("table", _hoisted_1, [
    _createVNode("thead", null, [
      _createVNode("tr", null, [
        (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.columns, (column, index) => {
          return (_openBlock(), _createBlock("th", {
            key: index,
            id: `${_ctx.replaceWhiteSpaces(column.name)}-${index}`,
            class: 
            `${
              column.styleHead
            } font-semibold bg-gray-lp-700 text-12px xxl:text-14px px-4 py-4 text-black-lp-200 ${index ===
              0 && 'rounded-l-lg'} ${index === _ctx.columns.length - 1 &&
              'rounded-r-lg'}`
          
          }, _toDisplayString(column.name), 11, ["id"]))
        }), 128))
      ])
    ]),
    _createVNode("tbody", null, [
      (_openBlock(), _createBlock(_Fragment, null, _renderList(5, (index) => {
        return _createVNode("tr", {
          key: index,
          class: "cursor-wait"
        }, [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.columns, (column, indexColumn) => {
            return (_openBlock(), _createBlock("td", {
              key: indexColumn,
              class: "px-4 py-6 xl:py-6 text-12px xxl:text-14px border-b border-gray-lp-400"
            }, [
              _createVNode(_component_Skeleton, { height: "1.3rem" })
            ]))
          }), 128))
        ])
      }), 64))
    ])
  ]))
}