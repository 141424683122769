
import { Options, Vue } from "vue-class-component";
import Skeleton from "@/app/ui/components/skeleton/index.vue";
import { replaceWhiteSpaces } from "@/app/infrastructures/misc/Utils";

@Options({
  components: {
    Skeleton
  },
  props: {
    columns: {
      default: [],
      type: Array
    }
  }
})
export default class TableLazyLoad extends Vue {
  replaceWhiteSpaces = replaceWhiteSpaces;
}
